import { useState } from "react";
import "./App.css";
import { Dialog, DialogPanel } from "@headlessui/react";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="h-screen w-screen relative flex  justify-center items-center">
      <img
        className="h-full w-full object-cover relative z-0"
        src="/images/1.jpg"
        alt="background"
      />

      <div className="h-[80%] w-[80%] p-4 bg-black/70 border border-slate-800 shadow shadow-slate-950  absolute z-20 rounded-xl ">
        <div className=" relative z-10 h-full w-full flex flex-col justify-center items-center text-gray-50">
          <img
            className="h-10 lg:h-16 xl:h-24 2xl:h-28 w-auto object-contain relative "
            src="/images/logoBangla.png"
            alt="background"
          />
          <img
            className="h-10 xl:h-16 2xl:h-28 w-72 object-contain relative z-0"
            src="/images/1.gif"
            alt="background"
          />
          <h1 className="relative text-3xl lg:text-5xl xl:text-7xl 2xl:text-9xl font-extrabold  text-center font-bungee uppercase">
            Coming soon
          </h1>
          <h3 className="relative uppercase text-sm lg:text-base 2xl:text-xl font-semibold text-center">
            Loading your digital security guard
          </h3>
          <p
            onClick={() => setIsOpen(true)}
            className="relative uppercase text-sm bg-teal-700 px-8 py-3 rounded-full cursor-pointer mt-4"
          >
            Get in touch
          </p>
          <p className="relative pt-16 text-sm text-center">
            A sister concern of{"   "}
            <a
              href="https://zicoretech.com/"
              target="_blank"
              className="text-red-700 font-alfa pl-2"
            >
              Zicore Technologies
            </a>
          </p>
        </div>
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4z bg-black/70">
            <DialogPanel className="w-full max-w-3xl  p-8 bg-black">
              <div className="bg-white rounded-lg h-fullx w-full">
                <iframe
                  src="https://docs.google.com/forms/d/e/1FAIpQLSeMZNYM5MqmpfKog87L6U-gfDTb-mda6371nHZrTqgS86sbfQ/viewform?embedded=true"
                  // width="100%"
                  // height="860"
                  className="w-full h-lvh md:h-[30rem] 2xl:h-[35rem] 3xl:h-[40rem] 4xl:h-[52rem]"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                >
                  Loading…
                </iframe>
                <button onClick={() => setIsOpen(false)}>
                  {" "}
                  <span className="text-white absolute lg:hidden top-1 right-2 w-6 h-6 border-2 text-center capitalize border-red-700 rounded-full">
                    {" "}
                    X
                  </span>
                </button>
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      </div>
    </div>
  );
}

export default App;
